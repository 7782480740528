import React from "react";
import {Redirect, Route} from "react-router-dom";
import Loading from "./loading/LoadingComponent"
import store from "../store/store"
import {changeFarm} from "../actions/locationAction";
import {getDevices} from "../actions/devicesActions";
import settingsDB from "../database/settingsDB";
import {getAllBuildings} from "../actions/farmsActions";
import {hasActiveSubscription} from "../utils/BraintreeUtils";
import _ from "lodash";
import {checkIfUserHasToSubscribe} from "../utils/NewRolesUtils";
import {getAnimalModificationTime} from "../actions/animalsActions";
import * as UserTypes from "validators-schema/Api/constants/userTypes";

/**
 * metoda przekierowująca w przypadku gdy uzytkownik nie jest zalogowany
 * nie pozwala zobaczyc stron do ktorych uzytkownik nie ma dostepu
 * np. widok innego uzytkownika
 *
 *  If the user is authenticated, then we simply render the passed in component.
 *  And if the user is not authenticated, then we use the Redirect React Rotuer v4
 *  component to redirect the user to the user page.
 *  ** */
export default ({component: C, props: cProps, ...rest}) => {
    let farmID = cProps.FarmID;
    let state = store.getState();
    let farm;
    try {
        farm = state.farms.farms.find(f => f.FarmID === (farmID || rest.computedMatch.params.FarmID));
    } catch (e) {
        farm = undefined;
    }
    if (!farmID && rest.computedMatch.params.FarmID && cProps.isAuthenticated) {
        farmID = rest.computedMatch.params.FarmID;
        if (farm && hasActiveSubscription(farm)) {
            store.dispatch(changeFarm(farmID));
            store.dispatch(getDevices(farmID));
            store.dispatch(getAnimalModificationTime(farmID));
            store.dispatch({
                type: "LIST_ALL_SETTINGS",
                payload: settingsDB.getAllSettings(farmID),
                meta: {
                    user: state.user.user
                }
            });
            store.dispatch(getAllBuildings(farmID));
            store.dispatch({
                type: "SHOW_NAVIGATION"
            });
        } else {
            store.dispatch(changeFarm(null));
        }

    }

    let role = _.get(store.getState(), "user.user.UserType", UserTypes.USER);
    let readIds = _.get(store.getState(), "user.user.DocRead", []);
    let documents = _.get(store.getState(), "documents.documents", []).filter(o => o.ForceRead && !readIds.includes(o.DocID) && (!o.ApplyTo || o.ApplyTo.includes(role)));
    let pendingDocument = documents[0];//true || C.pendingDocuments;
    let hasToSubscribe = !cProps.isAuthenticating && cProps.isAuthenticated && checkIfUserHasToSubscribe();
    const farmIdPath = rest.location.pathname.split("/").slice(-1)[0];
    const confirm = rest.location.pathname.split("/").slice(-3)[0];
    return (
        <Route
            {...rest}
            render={props =>
                cProps.isAuthenticating ?
                    <Loading isLoading={cProps.isAuthenticating}/>
                    :
                    cProps.isAuthenticated ?
                        hasToSubscribe ? (rest.location.pathname === "/getstarted" ? <C {...props} {...cProps}/> :
                            <Redirect
                                to={'/getstarted'}/>) : (pendingDocument ? (rest.location.pathname !== `/accept/${pendingDocument.DocID}` ?
                            <Redirect to={`/accept/${pendingDocument.DocID}`}/> :
                            <C {...props} {...cProps}/>) : (farmID && hasActiveSubscription(farm) ?
                            <C {...props} {...cProps}/>
                            : rest.path === "/farmChooser" || rest.location.pathname === "/settings/farms/add" || rest.location.pathname === ("/farms/manage/" + farmIdPath) || rest.location.pathname === ("/settings/license/subscriptions") || rest.location.pathname.includes("/farmSettings") ?
                                <C {...props} {...cProps}/>
                                : rest.location.pathname === "/settings/farms/add" ?
                                    <Redirect
                                        to={"/settings/farms/add"}/> : rest.location.pathname === ("/farms/manage/" + farmIdPath) ?
                                        <Redirect to={"/farms/manage/" + farmIdPath}/>
                                        : <Redirect to={"/farmChooser"}/>))
                        : confirm === "confirm" ? <C {...props} {...cProps}/> :
                        <Redirect
                            to={`/login?redirect=${props.location.pathname}${props.location.search}`}/>
            }
        />
    )
}